<template>
  <ActionList
    :show-search="false"
    :load-items-callback="loadItemsCallback"
    :fixed-filters="fixedFilters"
    :allow-export="false"
    v-on="$listeners"
  >
    <template v-slot:item="{ item }">
      <SimplifiedPaymentListItem
        :payment="item"
        :key="item.uuid"
        v-on="$listeners"
      />
    </template>
  </ActionList>
</template>

<script>
import ActionList from "@/components/common/list/ActionList"
import SimplifiedPaymentListItem from "@/components/payment/SimplifiedPaymentListItem"

export default {
	name: "SimplifiedPaymentsList",
	components: {
		SimplifiedPaymentListItem,
		ActionList
	},
	props: {
		payments: {
			type: Array,
			default: () => ([])
		},
		loadItemsCallback: {
			type: Function,
			default: () => null
		},
		fixedFilters: {
			type: Array,
			default: () => []
		},
	}
}
</script>

<style scoped>

</style>