<template>
  <v-list-item
    @click="$emit('selectPayment', payment)"
    class="payment-simplified-content on-hover"
  >
    <v-list-item-avatar
      :color="paymentColor"
      size="16"
      class="mx-12"
    />
    <v-list-item-content
      class="align-self-start"
      style="max-width: 200px!important;"
    >
      <v-list-item-title
        :class="paymentTextColor"
        class="font-weight-black text-h6"
      >
        {{ paymentAmount }}
      </v-list-item-title>
      <v-list-item-subtitle class="text-subtitle-2 grey--text">
        {{ paymentDateAndTime | moment('DD/MM/YYYY HH:mm:ss') }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content class="align-self-start flex-grow-1">
      <v-list-item-title class="font-weight-bold">
        {{ paymentChannel }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="!paymentIsSuccess">
        {{ paymentMessage }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon class="icon-no-hover">
        mdi-minus
      </v-icon>
      <v-icon class="icon-hover">
        mdi-eye-outline
      </v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
	name: "SimplifiedPaymentListItem",
	props: {
		payment: {
			type: Object,
			default: null
		}
	},
	computed: {
		paymentIsSuccess() {
			if(typeof this.payment === 'undefined' && this.payment === null) {
				return false
			}
			return this.payment.status === 'success'
		},
		paymentColor() {
			if(typeof this.payment === 'undefined' || this.payment === null) {
				return 'yellow'
			}
			if(this.payment.status === 'success') {
				return 'green'
			}
			if(this.payment.status === 'failure') {
				return 'red'
			}
			if(this.payment.status === 'pending') {
				return 'blue'
			}

			return 'yellow'
		},
		paymentTextColor() {
			return `${this.paymentColor}--text`
		},
		paymentAmount() {
			return this.$store.getters.formatAmountByCurrency(this.payment)
		},
		paymentDateAndTime() {
			if(typeof this.payment === 'undefined' || this.payment === null) {
				return 0
			}
			if(typeof this.payment.created === 'undefined' || this.payment.created === null) {
				return 0
			}
			return this.payment.created
		},
		paymentChannel() {
			if(typeof this.payment === 'undefined' || this.payment === null) {
				return ''
			}
			if(typeof this.payment.channel === 'undefined' || this.payment.channel === null) {
				return this.$t('Created in back-office')
			}
			return this.payment.channel.label
		},
		paymentMessage() {
			if(typeof this.payment === 'undefined' || this.payment === null) {
				return ''
			}
			if(typeof this.payment.message === 'undefined' || this.payment.message === null) {
				return ''
			}
			return this.payment.message
		},
		primaryColor() {
			return this.$vuetify.theme.currentTheme.primary
		}
	}
}
</script>

<style scoped>
.on-hover:hover >>> .icon-hover {
  display: inline;
}
.on-hover:hover >>> .icon-no-hover {
    display: none;
}
.icon-hover {
  display: none;
}
</style>